export const TableDetail = ({ children }) => {

    return (
        
        <table className="table table-bordered">
            <tbody className="bg-gray text-break">
                {children}
            </tbody>
        </table>
        
    );
}
