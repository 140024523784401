import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ links = [] }) => {

  return (
    <div className="nav-breadcrumb">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                {links.map((link, index) => (
                    <Fragment key={index}>
                        <li className="breadcrumb-item">
                            <Link to={link.path} className="bread-crumb-item">{link.name}</Link>
                        </li>
                    </Fragment>
                ))}
            </ol>
        </nav>
    </div>
  );

};

export default Breadcrumb;