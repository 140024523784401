import { addDays } from "date-fns"
import { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ActionFormButton } from "../components/form/ActionFormButton"
import { ActionFormButtonGroup } from "../components/form/ActionFormButtonGroup"
import { FormCheckInput } from "../components/form/FormCheckInput"
import { FormDateInput } from "../components/form/FormDateInput"
import { FormImageSelectInput } from "../components/form/FormImageSelectInput"
import { FormSelectInput } from "../components/form/FormSelectInput"
import { FormTextInput } from "../components/form/FormTextInput"
import { BodyContainer } from "../components/general/BodyContainer"
import { PageParagraph } from "../components/general/PageParagraph"
import { PageTitle } from "../components/general/PageTitle"
import { PageWithSidebar } from "../components/general/PageWithSidebar"
import { formatToUploadableDate, URL2File } from "../functions"
import useAxios from "../hooks/useAxios"
import { useFacultyInfo } from "../hooks/useFacultyInfo"
import { useForm } from "../hooks/useForm"
import { useLoading } from "../hooks/useLoading"
import { courseSchema } from "../schemas/courseSchema"

import useAlert from "../hooks/useAlert";


const initialValues = {
    title: '',
    faculty: '',
    ri: '',
    initDate: new Date(),
    endDate: addDays(new Date(), 30),
    contact: '',
    tel: '',
    ext: '',
    email: '',
    image: null,
    cost: '',
    publish: [],
};

export const AddCoursePage = () => {

    const { facID, courseID } = useParams();
    const { faculty: facObject } = useFacultyInfo({ id: facID });
    const { setLoading } = useLoading();
    const { get, put, post, del } = useAxios();
    const navigate = useNavigate();

    const { openAlert } = useAlert();

    const isEdit = !!courseID;
    const haveFaculty = !!facID;

    const facPart = haveFaculty ? "/" + facID : '';

    const links = useMemo(() => {
        return [
            { name: "Inicio", path: "/" },
            { name: "Cursos", path: "/courses" },
            ...(haveFaculty ? [{ name: facObject.name, path: `/courses/${facID}` }] : []),
            ...(isEdit ? [
                { name: "Editar curso", path: `/edit/course/${facID}/form/${courseID}` },
            ] : []),
            ...(!isEdit ? [
                { name: "Opciones", path: `/add/course${facPart}/options` },
                { name: "Agregar curso", path: `/add/course${facPart}/form` },
            ] : []),
        ];
    }, [facID, facObject]);

    const {
        title,
        faculty,
        ri,
        initDate,
        endDate,
        contact,
        tel,
        ext,
        email,
        cost,
        publish,
        image,
        onInputChange,
        setFormState,
        errors,
        validateFormState,
        hasBeenSubmited,
    } = useForm(
        haveFaculty
            ? { ...initialValues, faculty: facID }
            : initialValues,
        courseSchema,
    );

    const [imageURL, setImageURL] = useState('');
    const [imageInfo, setImageInfo] = useState({
        hadImageBefore: false,
        deletedImage: false,
    });
    const [faculties, setFaculties] = useState([{
        value: 'none',
        option: 'Seleccione una opción'
    }]);

    useEffect(() => {
        loadFaculties();
        if (isEdit) loadCourse();
    }, []);

    const loadCourse = async () => {
        try {
            setLoading(true);
            const resp = await get(`/cursos?id=${courseID}`);
            if (resp.status === 200) {
                const course = resp.data.items[0];
                //// console.log(course);
                setFormState(prev => ({
                    ...prev,
                    title: course.titulo,
                    ri: course.registro_institucional,
                    initDate: new Date(course.fecha_inicio),
                    endDate: new Date(course.fecha_final),
                    contact: course.contacto,
                    tel: course.telefono,
                    ext: course.extension,
                    email: course.correo,
                    cost: course.precio,
                    publish: course.publicado ? ['yes'] : [],
                }));
                setImageURL(course.img || '');
                setImageInfo({
                    ...imageInfo,
                    hadImageBefore: !!course.img,
                })
                return;
            }
            console.log(resp);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const loadFaculties = async () => {
        try {
            setLoading(true);
            const resp = await get(`facultades`);
            if (resp.status === 200) {
                const facs = resp.data.items;
                const mapped = facs.map(i => ({
                    value: i._id,
                    option: i.facultad,
                }));
                setFaculties(prev => [...prev, ...mapped]);
                return;
            }
            console.log(resp);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const onChangeImage = (event) => {
        const img = event.target.files[0];
        if (!!img) {
            onInputChange({ target: { name: 'image', value: img } });

            const url = !!img ? URL.createObjectURL(img) : '';
            setImageURL(url);
        }
    }

    const onRemoveImage = () => {
        onInputChange({ target: { name: 'image', value: null } });
        setImageURL('');
        setImageInfo({
            ...imageInfo,
            deletedImage: true,
        });
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        //// console.log({ ...formState, image });

        hasBeenSubmited();
        const isValid = validateFormState();
        if (!isValid) return;

        try {
            setLoading(true);

            const formData = new FormData();
            formData.append('titulo', title);
            formData.append('registro_institucional', ri);
            formData.append('facultad', faculty);
            formData.append('fecha_inicio', formatToUploadableDate(initDate));
            formData.append('fecha_final', formatToUploadableDate(endDate));
            formData.append('contacto', contact);
            formData.append('correo', email);
            formData.append('telefono', tel);
            formData.append('extension', ext);
            formData.append('precio', cost);
            formData.append('publicado', publish.includes('yes'));
            if (image) formData.append('archivo', image);

            let resp;
            if (!isEdit) {
                resp = await post(`cursos`, formData);
            } else {
                resp = await put(`cursos/${courseID}`, formData);
            }
            if (resp.status === 200) {
                if (imageInfo.deletedImage && imageInfo.hadImageBefore) {
                    resp = await del(`uploads/files/cursos/${courseID}`);
                }
                openAlert(
                    `Curso ${isEdit ? 'editado' : 'agregado'}`,
                    <>
                        El curso "<b>{title}</b>" ha
                        sido {isEdit ? 'editado' : 'agregado'} correctamente.
                    </>,
                    "success", false, null, null, 4000,
                )
                navigate(-1, { replace: true });
            }
            console.log(resp);
        } catch (error) {
            console.log(error);
            openAlert(
                `Error al ${isEdit ? 'editar' : 'agregar'} el curso`,
                <>
                    No se ha podido completar la operación
                    debido a un error inesperado, intentelo más tarde.
                </>,
                "error", false, null, null, 4000,
            )
        } finally {
            setLoading(false);
        }
    }

    // TODO: hacer una especie de state anterior para comparar y si cambió pues ya preguntar
    // const onModal = async (titulo) => {
    //     const respModal = await openAlert(
    //         "¿Cancelar acción?",
    //         <div>
    //             <p>
    //                 Al presionar "<span className="fw-bold">Aceptar</span>", el curso
    //                 <span className="fw-bold">{titulo}</span> no será {isEdit ? 'editado' : 'añadido'}.
    //             </p>
    //         </div>,
    //     )

    //     console.log(respModal);

    //     if (respModal) {
    //         navigate(-1);
    //         openAlert(
    //             <span>Acción cancelada</span>,
    //             <span>El curso<span className="fw-bold"> {titulo} </span>
    //                 no se {isEdit ? 'editó' : 'añadió'}.
    //             </span>,
    //             "warning"
    //         )
    //     }
    // }

    return (
        <PageWithSidebar links={links}>

            <form className="needs-validation" noValidate onSubmit={onSubmit}>

                <PageTitle>{isEdit ? "Editar" : "Agregar"} curso</PageTitle>
                <PageParagraph>
                    Llene el siguiente formulario.
                </PageParagraph>

                <BodyContainer>

                    <div className="row g-3">

                        <div className="col-md-12">
                            <FormTextInput
                                label="Título"
                                name="title"
                                value={title}
                                type="text"
                                required
                                onChange={onInputChange}
                                placeholder='Título del programa'
                                invalidFeedback={errors.title}
                            />

                            <FormSelectInput
                                label="Facultad"
                                disabled={haveFaculty}
                                name="faculty"
                                value={faculty}
                                options={faculties}
                                onChange={onInputChange}
                                invalidFeedback={errors.faculty}
                            />

                            <FormTextInput
                                label="Registro Institucional"
                                name="ri"
                                value={ri}
                                type="text"
                                required
                                onChange={onInputChange}
                                placeholder='Registro Institucional del programa'
                                invalidFeedback={errors.ri}
                            />
                        </div>

                        <div className="col-md-6">
                            <FormDateInput
                                label="Fecha de inicio"
                                name="initDate"
                                value={initDate}
                                required
                                onChange={onInputChange}
                                placeholder='DD/MM/AAAA'
                                invalidFeedback={errors.initDate}
                            />
                        </div>

                        <div className="col-md-6">
                            <FormDateInput
                                label="Fecha de terminación"
                                name="endDate"
                                value={endDate}
                                minDate={initDate}
                                required
                                onChange={onInputChange}
                                placeholder='DD/MM/AAAA'
                                invalidFeedback={errors.endDate}
                            />
                        </div>

                        <div className="col-md-12">

                            <FormTextInput
                                label="Contacto"
                                name="contact"
                                value={contact}
                                type="text"
                                required
                                onChange={onInputChange}
                                placeholder='Nombre del contacto'
                                invalidFeedback={errors.contact}
                            />

                        </div>

                        <div className="col-md-6">
                            <FormTextInput
                                label="Teléfono"
                                name="tel"
                                value={tel}
                                type="text"
                                required
                                onChange={onInputChange}
                                placeholder='Número telefónico'
                                invalidFeedback={errors.tel}
                            />
                        </div>

                        <div className="col-md-6">
                            <FormTextInput
                                label="Extensión"
                                name="ext"
                                value={ext}
                                type="text"
                                required
                                onChange={onInputChange}
                                placeholder='Extensión'
                                invalidFeedback={errors.ext}
                            />
                        </div>

                        <div className="col-md-12">

                            <FormTextInput
                                label="Correo electrónico"
                                name="email"
                                value={email}
                                type="email"
                                required
                                onChange={onInputChange}
                                placeholder='Correo electrónico del contacto'
                                invalidFeedback={errors.email}
                            />

                            <FormTextInput
                                label="Costo"
                                name="cost"
                                value={cost}
                                type="number"
                                required
                                onChange={onInputChange}
                                placeholder='Costo del curso ($MXN)'
                                invalidFeedback={errors.cost}
                            />

                            <FormImageSelectInput
                                label="Imagen"
                                name="image"
                                value={image}
                                src={imageURL}
                                altText="Previsualización de imágen"
                                onChange={onChangeImage}
                                onClickRemove={onRemoveImage}
                                invalidFeedback={errors.image}
                            />
                        </div>

                    </div>

                </BodyContainer>

                <PageParagraph>
                    Seleccione la casilla siguiente si quiere que el curso sea <b>público</b>.
                </PageParagraph>

                <BodyContainer>

                    <FormCheckInput
                        name="publish"
                        value={publish}
                        options={[
                            { value: 'yes', option: 'Publicado' },
                        ]}
                        onChange={onInputChange}
                        invalidFeedback={errors.publish}
                    />

                </BodyContainer>

                <ActionFormButtonGroup>
                    <ActionFormButton
                        classType='secondary'
                        type="button"
                        value="Cancelar"
                        onClick={() => navigate(-1)}
                    />
                    <ActionFormButton classType='info' type="submit" value="Aceptar" />
                </ActionFormButtonGroup>

            </form>

        </PageWithSidebar>
    )
}
