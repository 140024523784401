import { Navigate, Route, Routes } from "react-router-dom";

import useAxios from "../hooks/useAxios";
import useAlert from "../hooks/useAlert";
import UserProvider from "../providers/UserProvider";
import { useNavigate } from "react-router-dom";
import { DetailsUserPage } from '../pages/DetailsUserPage';
import { AddUserPage } from "../pages/AddUserPage";
import { AddCoursePage } from "../pages/AddCoursePage";
import { AddCourseByFilePage } from "../pages/AddCoursePageByFile"
import { AddCourseOptionsPage } from '../pages/AddCourseOptionsPage'
import { FacultyCoursesPage } from "../pages/FacultyCoursesPage";
import { UsersPage } from "../pages/UsersPage";
import { CoursesPage } from "../pages/CoursesPage";
import { HomePage } from "../pages/HomePage";
import { HelpPage } from "../pages/HelpPage";
import { HelpWithPage } from "../pages/HelpWithPage";
import { DetailsCoursePage } from "../pages/DetailsCoursePage";
import { TokenProvider } from "../providers/TokenProvider";
import { FacultiesProvider } from "../providers/FacultiesProvider";
import { ExportsPage } from "../pages/ExportsPage";
import { ExportViewPage } from "../pages/ExportViewPage";

const AdminRoutes = (props) => {
  const { openAlert } = useAlert();
  const { get, post } = useAxios();
  const navigate = useNavigate();

  return (
    <UserProvider>
      <TokenProvider>
        <FacultiesProvider>
          <Routes>
            <Route path="/" exact element={<HomePage />} />
            <Route path="/:userID" exact element={<HomePage />} />
            <Route path="/users" exact element={<UsersPage />} />
            <Route path="/user/:userID" exact element={<DetailsUserPage />} />
            <Route path="/add/user" exact element={<AddUserPage />} />
            <Route path="/edit/user/:userID" exact element={<AddUserPage />} />
            <Route path="/courses" exact element={<CoursesPage />} />
            <Route path="/courses/:facID" exact element={<FacultyCoursesPage />} />
            <Route path="/course/:courseID" exact element={<DetailsCoursePage />} />
            <Route path="/add/course/options" exact element={<AddCourseOptionsPage />} />
            <Route path="/add/course/:facID/options" exact element={<AddCourseOptionsPage />} />
            <Route path="/add/course/form" exact element={<AddCoursePage />} />
            <Route path="/add/course/:facID/form" exact element={<AddCoursePage />} />
            <Route path="/add/course/by-file" exact element={<AddCourseByFilePage />} />
            <Route path="/add/course/:facID/by-file" exact element={<AddCourseByFilePage />} />
            <Route path="/edit/course/:facID/form/:courseID" exact element={<AddCoursePage />} />
            <Route path="/exports" exact element={<ExportsPage />} />
            <Route path="/exports/:exportID" exact element={<ExportViewPage />} />
            <Route path="/help" exact element={<HelpPage />} />
            <Route path="/help/:helpID" exact element={<HelpWithPage />} />

            <Route path="/*" exact element={<Navigate to="/" />} />
          </Routes>
        </FacultiesProvider>
      </TokenProvider>
    </UserProvider>
  );
};

export default AdminRoutes;
