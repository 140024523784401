import React from "react";

// import SidebarPage from '../../components/SidebarPage'
import Breadcrumb from '../components/BreadCrumb'
import Stickybutton from '../components/StickyButton'


const links = [{ name: "Sesion cerrada", path: "/cerrarSesion" }];

export const LogoutPage = (props) => {
  return (
    // La página de cerrar sesión no debería tener el sidebar...
      
      <div className="content">
          <Stickybutton/>
          
          {/* Creo que tampoco necesita el bredcro */}
          <Breadcrumb links={links} />

          Cerraste sesion

        </div>

  );
};
