import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faUserPlus, faUserLock, faUserGear, faFileMedical , faFileArrowUp, faFilePen } from '@fortawesome/free-solid-svg-icons'
import { InstructionsAddUser } from './InstructionsAddUser'
import { InstructionsAddCourse } from './InstructionsAddCourse'
import { InstructionsModifyInfoUser } from './InstructionsModifyInfoUser'
import { InstructionsRestorePassword } from './InstructionsRestorePassword'
import { InstructionsAddCourseByFile } from './InstructionsAddCourseByFile'
import { InstructionsModifyCourse } from './InstructionsModifyCourse'

const iconStyle = "pe-2 fa-6x pb-3"
const classStyle = "btn btn-card pt-5 pb-3"


export const HelpData = [
    {
        title : 'Agregar un usuario',
        path : 'add-user',
        icon : faUserPlus,
        classIcon : iconStyle,
        className : classStyle,
        instructions : InstructionsAddUser,
    },
    {
        title : 'Cambiar información del usuario',
        path : 'modify-user-info',
        icon : faUserGear,
        classIcon : iconStyle,
        className : classStyle,
        instructions : InstructionsModifyInfoUser,
    },
    {
        title : 'Reestablecer contraseña',
        path : 'restore-password',
        icon : faUserLock,
        classIcon : iconStyle,
        className : classStyle,
        instructions : InstructionsRestorePassword,
    },
    {
        title : 'Agregar un curso',
        path : 'add-course',
        icon : faFileMedical,
        classIcon : iconStyle,
        className : classStyle,
        instructions : InstructionsAddCourse,
    },
    {
        title : 'Agregar un curso por archivo',
        path : 'add-course-by-file',
        icon : faFileArrowUp,
        classIcon : iconStyle,
        className : classStyle,
        instructions : InstructionsAddCourseByFile,
    },
    {
        title : 'Modificar un curso',
        path : 'modify-course',
        icon : faFilePen,
        classIcon : iconStyle,
        className : classStyle,
        instructions : InstructionsModifyCourse,
    },
]