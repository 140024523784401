
export const CodeTextArea = ({
    name,
    value,
    onChange,
    disabled,
    required = false,
    placeholder,
    rows = 2,
    className,
}) => {
    return (
        <div className="mb-3">
        <code>
            <textarea
                name={name}
                value={value}
                onChange={onChange}
                disabled={disabled}
                required={required}
                placeholder={placeholder}
                rows={rows}
                className={"form-control " + className}
            />
        </code>
        </div>
    )
}
