import { useEffect, useLayoutEffect, useMemo, useState } from "react";

import { PageTitle } from "../components/general/PageTitle";
import { PageParagraph } from "../components/general/PageParagraph";
import { PageWithSidebar } from "../components/general/PageWithSidebar";
import { BodyContainer } from "../components/general/BodyContainer"
import { LargeButton } from "../components/general/LargeButton";
import { Link } from "react-router-dom";

import { ActionFormButton } from "../components/form/ActionFormButton"
import { ActionFormButtonGroup } from "../components/form/ActionFormButtonGroup"
import { TableDetail } from '../components/details/TableDetail'
import { RowDetail } from '../components/details/RowDetail'

import imgUser from "../assets/img/imgUser.png";

import useAxios from "../hooks/useAxios";
import { useNavigate, useParams } from "react-router-dom";
import { useLoading } from "../hooks/useLoading";
import { useFacultyInfo } from "../hooks/useFacultyInfo";
import useAlert from "../hooks/useAlert";
import { obtainRole } from "../functions";


const dummyData = {
    name: "---",
    email: "---",
    role: "---",
    faculty: "---",
    img: imgUser,
};

export const DetailsUserPage = (props) => {

  

  const { get, del } = useAxios();
  const { userID } = useParams();
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  const [user, setUser] = useState(dummyData);
  const { faculty } = useFacultyInfo({ id: user.facultad });

  const { openAlert } = useAlert();

  const links = useMemo(() => {
    return [
      { name: "Inicio", path: "/" },
      { name: "Cursos", path: "/courses" },
      { name: faculty.name, path: `/courses/${faculty.id}` },
      { name: "Ver curso", path: `/course/${userID}` },
    ];
  }, [faculty, userID]);

  useLayoutEffect(() => {
    onLoadedPage();
  }, []);

  const onLoadedPage = async () => {
    try {
      setLoading(true);
      const resp = await get(`usuarios?id=${userID}`);
      if (resp.status === 200) {
        setUser(resp.data.items);
        return;
      }
      console.log(resp);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const onDelete = async () => {
    try {
      // TODO: poner un modal chido que pregunte si sí o nel
      setLoading(true);
      const resp = await del(`usuarios/${userID}`);
      navigate(-1, { replace: true });
      console.log(resp);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };


  
  
    // TODO : El modal pregunta y si se conofirma se elimina el curso
    const onModalConfirm = async (nombre,rol) => {
      const respModal = await openAlert(
          <span>Eliminar {obtainRole(rol)}</span>,
          <div>
              <p>Esta apunto de eliminar el {obtainRole(rol)} "<span className="fw-bold">{nombre}</span>"
              </p>
              <p>¿Desea elminarlo definitivamente?</p>
          </div>,
      )

      if (respModal) {
          onDelete()
          openAlert(
              <span>{obtainRole(rol)} eliminado</span>,
              <span>El {obtainRole(rol)}<span className="fw-bold"> {nombre} </span>
                  se ha eliminado correctamente
              </span>,
              "success"
          )
      } else {
          openAlert(
              <span>Eliminación cancelada</span>,
              <span>El {obtainRole(rol)}<span className="fw-bold"> {nombre} </span>
                  no se elimino porque cancelo su eliminación
              </span>,
              "error"
          )
      }
  }


  return (

    <PageWithSidebar links={links}>
      <PageTitle>Detalles del Usuario</PageTitle>

      <div className="row g-3 ">
          <div className="col-md-4">
            <div className="text-center">
                <img src={user.img || imgUser} 
                  id="imgSelected" 
                  className="img-fluid img-margin img-user-size img-border"
                  alt="Seleccione una imagen para el curso"
                />
              </div>
          </div>
          <div className="col-md-8">
            <TableDetail>
                <RowDetail
                  header = "Nombre" fields = {[user.nombre]} colSpan = {2}
                />
                <RowDetail
                  header = "Correo electrónico" fields = {[user.correo]} colSpan = {2}
                />
                <RowDetail
                  header = "Rol" 
                  fields = {[user.rol]} 
                  colSpan = {2}
                />
                <RowDetail
                  header = "Facultad o cargo" fields = {[faculty.name]} colSpan = {2}
                />
            </TableDetail>
          </div>
      </div>

      <ActionFormButtonGroup>
      <ActionFormButton 
          classType='info' 
          type="button" 
          value="Editar" 
          onClick={() => navigate(`/edit/user/${userID}`)} 
        />
        <ActionFormButton 
          classType='danger' 
          type="button" 
          value="Borrar" 
          onClick={() => onModalConfirm([user.nombre], [user.rol])} 
        />
      </ActionFormButtonGroup>

    </PageWithSidebar>

  );
};