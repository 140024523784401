import imagen from '../../assets/img/img-default.png'


export const InstructionsModifyInfoUser = [
    {
      p : 'Primero se tiene que dirigir a la seccion de ',
      span : '"usuarios"',
      image : imagen
    },
    {
      p : 'Despues, de click en el botón que dice ',
      span : '"+ Agregar usuarios"',
      image : imagen
    },
    {
      p : 'Por ultimo, llene los datos del usuario y de click en ',
      span : '"Aceptar"',
      image : imagen
    },
  ]