import { PageWithSidebar } from "../components/general/PageWithSidebar";
import { PageTitle } from "../components/general/PageTitle";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HelpData } from "../data/help/HelpData";
import { PageParagraph } from "../components/general/PageParagraph";
import { LargeButton } from "../components/general/LargeButton";

export const HelpWithPage = () => {

  const navigate = useNavigate();
  const { helpID } = useParams();

  const helpSection = HelpData.find(help => help.path === helpID);

  if (!helpSection) navigate('/help/');

  const links = [
    { name: "Inicio", path: "/" },
    { name: "Ayuda", path: "/help" },
    { name: helpSection.title, path: "/help/" + helpSection.path },
  ];

  return (
    <PageWithSidebar links={links}>
      <PageTitle>{helpSection.title}</PageTitle>

      <div className="row g-3 pb-3">
        {helpSection.instructions.map((item, index) => {
          return (
            <div key={index} className="col-md-12">
              <PageParagraph>
                {item.p}
                <span className="fw-bold">
                  {item.span}
                </span>
              </PageParagraph>
              <img src={item.image} className="img-fluid img-margin img-border" alt="Imagen"></img>
            </div>
          );
        })}
      </div>
      <Link to="/help">
        <LargeButton value="Regresar" classType="secondary" />
      </Link>
    </PageWithSidebar>
  );
};
