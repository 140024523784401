import React from "react";
import TablePagination from "@mui/material/TablePagination";
import "../../css/pagination.css";

export const Pagination = ({
  total = 10,
  pageNumber = 1,
  pageSize = 10,
  onChangePageNumber = () => {},
  onChangePageSize = () => {},
  debug = false,
}) => {
  if (debug) console.log({ total, pageNumber, pageSize });
  return (
    <div className="table-pagination">
      <TablePagination
        component="div"
        count={total}
        page={pageNumber}
        onPageChange={onChangePageNumber}
        rowsPerPage={pageSize}
        onRowsPerPageChange={onChangePageSize}
        labelRowsPerPage="Elementos por página: "
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
      />
    </div>
  );
};
