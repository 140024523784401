import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faHome, faUser, faMortarBoard, faFileCode, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

const iconStyle = "fa-xl nav-link-icon"
const classStyle = ""

export const SidebarData = [
    {
        title : 'Inicio',
        path : '/',
        icon : <FontAwesomeIcon icon={ faHome } className={iconStyle}/>,
        cName : classStyle
    },
    {
        title : 'Usuarios',
        path : '/users',
        icon : <FontAwesomeIcon icon={ faUser } className={iconStyle}/>,
        cName : classStyle
    },
    {
        title : 'Cursos',
        path : '/courses',
        icon : <FontAwesomeIcon icon={ faMortarBoard } className={iconStyle}/>,
        cName : classStyle
    },
    {
        title : 'Exportaciones',
        path : '/exports',
        icon : <FontAwesomeIcon icon={ faFileCode } className={iconStyle}/>,
        cName : classStyle
    },
    {
        title : 'Ayuda',
        path : '/help',
        icon : <FontAwesomeIcon icon={ faQuestionCircle } className={iconStyle}/>,
        cName : classStyle
    },
]