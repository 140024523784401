import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { BodyContainer } from "../general/BodyContainer";
import { SearchBar } from '../table/SearchBar';

// This is literally a fork from original Table.jsx
export const FacultyTable = ({
  faculties = [{ name: "Facultad", id: "FCC" }],
  total = 10,
  searchValue = '',
  onSubmitSearchValue = () => { },
  onChangeSearchValue = () => { },
}) => {
  return (
    <>
      <div className="mb-3">
        <SearchBar
          searchValue={searchValue}
          onChangeSearchValue={onChangeSearchValue}
          onSubmitSearchValue={onSubmitSearchValue}
          total={total}
        />
      </div>

      {faculties.map(({ name, id }) => (
        <BodyContainer key={`${id}-key`}>
          <Link to={`/courses/${id}`} className="text-uni">
            <FontAwesomeIcon
              icon={faGraduationCap}
              className="pe-2 fa-lg"
            />
            {name}
          </Link>
        </BodyContainer>
      ))}
    </>
  );
};
