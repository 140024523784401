import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { LargeButton } from "../components/general/LargeButton";
import { PageWithSidebar } from "../components/general/PageWithSidebar";
import { PageParagraph } from "../components/general/PageParagraph";
import { PageTitle } from "../components/general/PageTitle";
import { ActionButton } from "../components/table/ActionButton";
import { ActionButtonGroup } from "../components/table/ActionButtonGroup";
import { Table } from "../components/table/Table";
import { TableRow } from "../components/table/TableRow";
import useTable from "../hooks/useTable";
import { formatRole } from "../functions";
import { useForm } from "../hooks/useForm";
import { Link } from "react-router-dom";

import useAxios from "../hooks/useAxios";
import { useLoading } from "../hooks/useLoading";
import useAlert from "../hooks/useAlert";
import { obtainRole } from "../functions";


const links = [
  { name: "Inicio", path: "/" },
  { name: "Usuarios", path: "/users" },
];

const fields = ["Nombre", "Correo electrónico", "Rol", "Facultad", "Acciones"];



export const UsersPage = () => {

const { setLoading } = useLoading();
const { del } = useAxios();

const { openAlert } = useAlert();

  const usersTable = useTable({
    endpoint: "/usuarios",
  });
  const navigate = useNavigate();

  const { search, onInputChange } = useForm({ search: '' });

  const onEdit = (uid) => navigate(`/edit/user/${uid}`);
  const onView = (uid) => navigate(`/user/${uid}`);
  const onDelete = async (id) => {
    try {
        setLoading(true);
        const resp = await del(`usuariosXD/${id}`); //Aun no voy eliminar usuatios
        await usersTable.search();
        console.log(resp);
    } catch (error) {
        console.log(error);

        openAlert(
            <span>Error en la eliminación</span>,
            <span>No se ha podido completar la eliminación
                debido a un error inesperado, intentelo más tarde
            </span>,
            "error"
        )
    } finally {
        setLoading(false);
    }
};

  
    // TODO : El modal pregunta y si se conofirma se elimina el curso
    const onModalConfirm = async (id, usuario, rol) => {
        const respModal = await openAlert(
            <span>Eliminar {obtainRole(rol)}</span>,
            <div>
                <p>Esta apunto de eliminar el {obtainRole(rol)} 
                    " <span className="fw-bold">{usuario}</span>"
                </p>
                <p>¿Desea elminarlo definitivamente?</p>
            </div>,
        )

        if (respModal) {
            onDelete(id)
            openAlert(
                <span>{obtainRole(rol)} eliminado</span>,
                <span>El {obtainRole(rol)}
                <span className="fw-bold"> {usuario} </span>
                se ha eliminado correctamente
                </span>,
                "success"
            )
        } else {
            openAlert(
                <span>Eliminación del {obtainRole(rol)} cancelada</span>,
                <span>El {obtainRole(rol)}
                <span className="fw-bold"> {usuario} </span>
                    no se elimino porque haz cancelado su eliminación
                </span>,
                "error"
            )
        }
    }


  const onSubmitSearch = (event) => {
    event.preventDefault();

    const value = search.trim();
    usersTable.removeExtraParam('nombre');
    if (value.length > 0) {
      usersTable.addExtraParams({nombre: value});
    }
  }

  return (
    <PageWithSidebar links={links}>
      <PageTitle>Administrar usuarios</PageTitle>
      <PageParagraph>Lista de usuarios en la plataforma.</PageParagraph>

      <Link to="/add/user">
        <LargeButton value="+ Agregar usuario" />
      </Link>

      <Table
        fields={fields}
        total={usersTable.total}
        pageSize={usersTable.pageSize}
        pageNumber={usersTable.pageNumber}
        onChangePageSize={usersTable.onChangePageSize}
        onChangePageNumber={usersTable.onChangePageNumber}
        searchValue={search}
        onChangeSearchValue={onInputChange}
        onSubmitSearchValue={onSubmitSearch}
      >
        {usersTable.elements.map((el) => (
          <TableRow
            key={el.uid}
            values={[
              el.nombre,
              el.correo,
              formatRole(el.rol),
              _.get(el, "facultad.facultad", ""),
              <ActionButtonGroup>
                <ActionButton
                  key="btn-view"
                  value="Ver"
                  onClick={() => onView(el.uid)}
                />
                <ActionButton
                  key="btn-edit"
                  value="Editar"
                  onClick={() => onEdit(el.uid)}
                />
                <ActionButton
                  key="btn-delete"
                  value="Borrar"
                  onClick={() => onModalConfirm(el.uid, el.nombre, el.rol)}
                />
              </ActionButtonGroup>,
            ]}
          />
        ))}
      </Table>
    </PageWithSidebar>
  );
};
