import { Link, useNavigate, useParams } from "react-router-dom";
import { LargeButton } from "../components/general/LargeButton";
import { PageWithSidebar } from "../components/general/PageWithSidebar";
import { PageParagraph } from "../components/general/PageParagraph";
import { PageTitle } from "../components/general/PageTitle";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { useMemo } from "react";
import { useFacultyInfo } from "../hooks/useFacultyInfo";


import { CardButton } from "../components/form/CardButton";


export const AddCourseOptionsPage = () => {
    const { facID } = useParams();
    const facPart = facID ? "/"+facID : '';
    const navigate = useNavigate();

    const { faculty } = useFacultyInfo({ id: facID });

    const links = useMemo(() => {
        return [
            { name: "Inicio", path: "/" },
            { name: "Cursos", path: "/courses" },
            ...(facID ? [
                { name: faculty.name, path: `/courses/${facID}` }
            ]: []),
            { name: "Opciones", path: `/add/course${facPart}/options` },
        ];
    }, [faculty, facID]);

    const onClickBack = () => {
        navigate(-1);
    }

    return (

        <PageWithSidebar links={links}>
            <PageTitle>Opciones para agregar curso</PageTitle>

            <PageParagraph>
                Seleccione una opción.
            </PageParagraph>

            <div className="row g-3 pt-2 pb-4">


                <CardButton
                    col = "col-md-6"
                    path = {`/add/course${facPart}/form`}
                    className = "btn btn-card"
                    classNameTitle = "pt-3"
                    classNameIcon = "pe-2 fa-8x"
                    icon = {faGraduationCap}
                    title = "Llenar el formulario"
                />

                <CardButton
                    col = "col-md-6"
                    path = {`/add/course${facPart}/by-file`}
                    className = "btn btn-card"
                    classNameTitle = "pt-3"
                    classNameIcon = "pe-2 fa-8x"
                    icon = {faGraduationCap}
                    title = "Subir un archivo en formato EXCEL"
                />

                {/*
                <div className="col-md-6">
                    <Link to={`/add/course${facPart}/form`} className="btn btn-card">
                        <FontAwesomeIcon icon={faGraduationCap} className={"pe-2 fa-8x"} />
                        <p className="pt-3">Llenar el formulario</p>
                    </Link>
                </div>

                <div className="col-md-6">
                    <Link to={`/add/course${facPart}/by-file`} className="btn btn-card">
                        <FontAwesomeIcon icon={faGraduationCap} className={"pe-2 fa-8x"} />
                        <p className="pt-3">Subir un archivo en formato EXCEL</p>
                    </Link>
                </div>
                */}


            </div>

            <LargeButton value="Regresar" classType="secondary" onClick={onClickBack} />

        </PageWithSidebar>

    );
}
