import { useEffect, useLayoutEffect, useMemo, useState } from "react";

import { PageTitle } from "../components/general/PageTitle";
import { PageWithSidebar } from "../components/general/PageWithSidebar";
import { useNavigate, useParams } from "react-router-dom";

import { ActionFormButton } from "../components/form/ActionFormButton"
import { ActionFormButtonGroup } from "../components/form/ActionFormButtonGroup"
import { TableDetail } from '../components/details/TableDetail'
import { RowDetail } from '../components/details/RowDetail'

import imgCourse from "../assets/img/img-default.png";
import useAxios from "../hooks/useAxios";
import { formatToSimpleDate } from "../functions";
import { useLoading } from "../hooks/useLoading";
import { useFacultyInfo } from "../hooks/useFacultyInfo";
import useAlert from "../hooks/useAlert";

const dummyData = {
  publicado: false,
  facultad: "---", // mejor un objeto jaja
  titulo: "---",
  registro_institucional: "---",
  fecha_inicio: undefined,
  fecha_final: undefined,
  contacto: "---",
  telefono: "---",
  extension: "---",
  precio: 0,
  correo: "---",
  img: imgCourse,
};

export const DetailsCoursePage = () => {
  const { get, del } = useAxios();
  const { courseID } = useParams();
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  const [course, setCourse] = useState(dummyData);
  const { faculty } = useFacultyInfo({ id: course.facultad });

  const { openAlert } = useAlert();

  const links = useMemo(() => {
    return [
      { name: "Inicio", path: "/" },
      { name: "Cursos", path: "/courses" },
      { name: faculty.name, path: `/courses/${faculty.id}` },
      { name: "Ver curso", path: `/course/${courseID}` },
    ];
  }, [faculty, courseID]);

  useLayoutEffect(() => {
    onLoadedPage();
  }, []);

  const onLoadedPage = async () => {
    try {
      setLoading(true);
      const resp = await get(`cursos?id=${courseID}`);
      if (resp.status === 200) {
        setCourse(resp.data.items[0]);
        return;
      }
      console.log(resp);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const onDelete = async (title) => {
    const resp = await openAlert(
      "Eliminar curso",
      <>
        Está apunto de eliminar el curso "<b>{title}</b>".
        <br />
        ¿Desea elminarlo definitivamente?
      </>,
    )
    if (resp) {
      const result = await deleteCourse(courseID);
      if (result) {
        openAlert(
          "Curso eliminado",
          <>
            El curso "<b>{title}</b>" se ha eliminado
            correctamente.
          </>,
          "success", false, null, null, 4000,
        );
        navigate(-1, { replace: true });
      } else {
        openAlert(
          "Error en la eliminación",
          <>
            No se ha podido completar la eliminación
            debido a un error inesperado, intentelo más tarde.
          </>,
          "error", false, null, null, 4000,
        )
      }
    }
  };


  const deleteCourse = async () => {
    let result = false;
    try {
      setLoading(true);
      const resp = await del(`cursos/${courseID}`);
      console.log(resp);
      result = true;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      return result;
    }
  };

  return (

    <PageWithSidebar links={links}>

      <PageTitle>Detalles del curso</PageTitle>

      <TableDetail>
        <RowDetail
          header="Título del programa" fields={[course.titulo]} colSpan={2}
        />
        <RowDetail
          header="Facultad" fields={[faculty.name]} colSpan={2}
        />
        <RowDetail
          header="Registro Institucional" fields={[course.registro_institucional]} colSpan={2}
        />
        <RowDetail
          header="Fecha de inicio y terminación"
          fields={[
            !!course.fecha_inicio ? formatToSimpleDate(course.fecha_inicio) : '---',
            !!course.fecha_final ? formatToSimpleDate(course.fecha_final) : '---',
          ]}
          colSpan={1}
        />
        <RowDetail
          header="Costo" fields={[`$ ${course.precio} MXN`]} colSpan={2}
        />
      </TableDetail>

      <TableDetail>
        <RowDetail
          header="Contacto" fields={[course.contacto]} colSpan={2}
        />
        <RowDetail
          header="Teléfono y extensión"
          fields={[course.telefono, course.extension]}
          colSpan={1}
        />
        <RowDetail
          header="Email" fields={[course.correo]} colSpan={2}
        />
      </TableDetail>

      <TableDetail>
        <RowDetail
          header="Publicado" fields={[course.publicado ? "Sí" : "No"]} colSpan={2}
        />
      </TableDetail>

      <div className="mb-3">
        <img
          src={course.img || imgCourse}
          id="imgCurso"
          className="img-fluid mx-auto d-block"
          alt="Imagen del curso"
        />
      </div>

      <ActionFormButtonGroup>
        <ActionFormButton
          classType='info'
          type="button"
          value="Editar"
          onClick={() => navigate(`/edit/course/${faculty.id}/form/${courseID}`)}
        />
        <ActionFormButton
          classType='danger'
          type="button"
          value="Borrar"
          onClick={() => onDelete(course.titulo)}
        />
      </ActionFormButtonGroup>

    </PageWithSidebar>

  );
};
