import { createContext, useState } from "react";

export const TokenContext = createContext({});

const init = () => {
    const token = localStorage.getItem('token') || '';
    const refresh = localStorage.getItem('refresh') || '';

    return { token, refresh };
}

export const TokenProvider = ({ children }) => {

    const [state, setState] = useState(init());

    const setToken = (token) => {
        localStorage.setItem('token', token);
        setState(prev => ({ ...prev, token, }));
    };
    
    const setRefresh = (refresh) => {
        localStorage.setItem('refresh', refresh);
        setState(prev => ({ ...prev, refresh, }));
    }
    
    const freeTokens = () => {
        localStorage.setItem('token', '');
        localStorage.setItem('refresh', '');
        setState({ token: '', refresh: '', });
    };

    return (
        <TokenContext.Provider value={{ ...state, setToken, setRefresh, freeTokens }}>
            {children}
        </TokenContext.Provider>
    );
}
