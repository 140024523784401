
export const ActionFormButtonGroup = ({ children }) => {
    
    return (
        <div className="py-1 mb-3">
            <div className="row">
                {children}
            </div>
        </div>
    )
}
