import useAxios from "../hooks/useAxios";
import { useNavigate } from "react-router";
import useAlert from "../hooks/useAlert";
import { useForm } from "../hooks/useForm";

import { Link } from "react-router-dom";

import { FormTextInput } from "../components/form/FormTextInput"
import { PageWithCenteredSquare } from "../components/general/PageWithCenteredSquare";

export const LoginPage = (props) => {
  const { email, password, onInputChange } = useForm({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const { post } = useAxios();
  const { openAlert } = useAlert();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email || !password)
      await openAlert("Por favor escribe un correo y una contraseña");

    try {
      const body = { correo: email, password };
      const response = await post("/auth/login", body);
      if (response.data && response.data.token && response.data.refresh) {
        const token = response.data.token;
        const refresh = response.data.refresh;

        localStorage.setItem('token', token);
        localStorage.setItem('refresh', refresh);

        // navigate("/", { replace: true });
        window.location.reload(false);
      }
    } catch (error) {
      await openAlert(
        "Email o contraseña incorrectos. Por favor, intente de nuevo"
      );
      console.log({ error });
    }
  };

  const inputFeedBack = () => {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.querySelectorAll('.needs-validation')

    // Loop over them and prevent submission
    Array.from(forms).forEach(form => {
      form.addEventListener('submit', event => {
        if (!form.checkValidity()) {
          event.preventDefault()
          event.stopPropagation()
        }

        form.classList.add('was-validated');
      }, false)
    })
  }

  return (
    <PageWithCenteredSquare>
      <form onSubmit={handleSubmit} className="needs-validation " noValidate>

        <div className="py-2">
          <FormTextInput
            label="Correo electrónico"
            name="email"
            value={email}
            type="text"
            required={true}
            onChange={onInputChange}
            placeholder='Ingrese su correo electronico'
            invalidFeedback="Ingrese su correo"
          />
        </div>

        <div className="py-1">
          <FormTextInput
            label="Contraseña"
            name="password"
            value={password}
            type="password"
            required={true}
            onChange={onInputChange}
            placeholder='Ingrese su contraseña'
            invalidFeedback="Ingrese su contraseña"
          />
        </div>

        <div className="py-4">
          <div className="d-grid gap-2">
            <button
              className="btn btn-secondary btn-blue border text-button"
              type="submit"
              onClick={inputFeedBack}>
              Iniciar sesión
            </button>
          </div>
        </div>

        <div className="login-button-center">
          <Link to="/forgot-password" className="btn btn-link login-button-decoration">
            ¿Olvido su contraseña?
          </Link>
        </div>

      </form>
    </PageWithCenteredSquare>
  );
};
