import React from "react";
import { createRoot } from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "./styles/SidebarX.css";
import "./styles/index.css";
import App from "./App";

const container = document.getElementById("root");
const root = createRoot(container);

// Is this the REACT FAMOUS "HELLO WORLD"?! OMG SO GOOD OOOHHHHH
root.render(<App />);
