import * as yup from 'yup';

const MAX_FILE_SIZE = 2000000;

export const courseSchema = yup.object({
    title: yup.string()
        .required('El título es obligatorio'),
    faculty: yup.string()
        .notOneOf(['none'], 'Seleccione una opción válida')
        .required('La facultad es obligatoria'),
    ri: yup.string()
        .required('El Registro Institucional es obligatorio'),
    initDate: yup.date()
        .required('La fecha de inicio es obligatoria'),
    endDate: yup.date().when(
        "initDate",
        (initDate, yup) => initDate && yup.min(initDate, "La fecha de terminación no puede ser antes que la de inicio"))
        .required('La fecha de terminación es obligatoria'),
    contact: yup.string()
        .required('El nombre del contacto es obligatorio'),
    tel: yup.string()
        .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, 'El número de teléfono no es válido')
        .required('El número de teléfono es obligatorio'),
    ext: yup.string()
        .matches(/^\d{4}$/i, 'El número de extensión no es válido')
        .required('El número de extensión es obligatorio'),
    email: yup.string()
        .email('El correo electrónico no es válido')
        .required('El correo electrónico es obligatorio'),
    image: yup.mixed()
        .test("fileSize", "El archivo es muy grande (mayor que 2 MB)", (value) => {
            if (value) return value.size <= MAX_FILE_SIZE;
            return true;
        }),
    cost: yup.string()
        .test('isNumber', 'El número no es válido', n => !isNaN(n))
        .test('isGreaterThanOrEqual', 'El costo no puede ser negativo', n => n >= 0)
        .required('El costo es obligatorio'),
    publish: yup.array()
        .test('hasCorrectValue', 'El valor de casilla no es válido', (value) => {
            if (value.length > 0) return value[0] === 'yes';
            return true;
        })
        .required('La opción de "publicado" es obligatoria'),
});