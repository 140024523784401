import { Link, useNavigate, useParams } from "react-router-dom";
import { LargeButton } from "../components/general/LargeButton";
import { PageWithSidebar } from "../components/general/PageWithSidebar";
import { PageParagraph } from "../components/general/PageParagraph";
import { PageTitle } from "../components/general/PageTitle";
import { ActionButton } from "../components/table/ActionButton";
import { ActionButtonGroup } from "../components/table/ActionButtonGroup";
import { Table } from "../components/table/Table";
import { TableRow } from "../components/table/TableRow";
import useTable from "../hooks/useTable";
import { useForm } from "../hooks/useForm";
import { formatToSimpleDate } from "../functions";
import { useMemo } from "react";
import { useFacultyInfo } from "../hooks/useFacultyInfo";
import useAxios from "../hooks/useAxios";
import { useLoading } from "../hooks/useLoading";

import useAlert from "../hooks/useAlert";


const fields = [
    'Título',
    'Registro Institucional',
    'Fecha de inicio y terminación',
    'Costo',
    'Publicado',
    'Acciones',
];

export const FacultyCoursesPage = () => {
    const { facID } = useParams();
    const facPart = facID ? "/" + facID : '';
    const navigate = useNavigate();
    const { faculty } = useFacultyInfo({ id: facID });

    const { setLoading } = useLoading();
    const { del } = useAxios();

    const { openAlert } = useAlert();


    const table = useTable({
        endpoint: "/cursos",
        xParams: {
            facultad: facID,
        }
    });
    const { search, onInputChange } = useForm({ search: '' });

    const links = useMemo(() => {
        return [
            { name: "Inicio", path: "/" },
            { name: "Cursos", path: "/courses" },
            { name: faculty.name, path: `/courses/${facID}` },
        ];
    }, [faculty, facID]);

    const onEdit = (id) => navigate(`/edit/course/${facID}/form/${id}`);
    const onView = (id) => navigate(`/course/${id}`);
    const onDelete = async (id, title) => {
        const resp = await openAlert(
            "Eliminar curso",
            <>
                Está apunto de eliminar el curso "<b>{title}</b>".
                <br />
                ¿Desea elminarlo definitivamente?
            </>,
        )
        if (resp) {
            const result = await deleteCourse(id);
            result
                ? openAlert(
                    "Curso eliminado",
                    <>
                        El curso "<b>{title}</b>" se ha eliminado
                        correctamente.
                    </>,
                    "success", false, null, null, 4000,
                )
                : openAlert(
                    "Error en la eliminación",
                    <>
                        No se ha podido completar la eliminación
                        debido a un error inesperado, intentelo más tarde.
                    </>,
                    "error", false, null, null, 4000,
                )
        }
    };

    const deleteCourse = async (id) => {
        let result = false;
        try {
            setLoading(true);
            const resp = await del(`cursos/${id}`);
            await table.search();
            console.log(resp);
            result = true;
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
            return result;
        }
    }

    const onSubmitSearch = (event) => {
        event.preventDefault();

        const value = search.trim();
        table.removeExtraParam('nombre');
        if (value.length > 0) {
            table.addExtraParams({ nombre: value });
        }
    }

    return (
        <PageWithSidebar links={links}>

            <PageTitle>Lista de cursos</PageTitle>
            <PageParagraph>
                Lista de cursos de la {faculty.name}.
            </PageParagraph>

            <Link to={`/add/course${facPart}/options`}>
                <LargeButton value="+ Agregar curso" />
            </Link>

            <Table
                fields={fields}
                total={table.total}
                pageSize={table.pageSize}
                pageNumber={table.pageNumber}
                onChangePageSize={table.onChangePageSize}
                onChangePageNumber={table.onChangePageNumber}
                searchValue={search}
                onChangeSearchValue={onInputChange}
                onSubmitSearchValue={onSubmitSearch}
            >
                {table.elements.map((c) => (
                    <TableRow
                        key={c._id}
                        values={[
                            c.titulo,
                            c.registro_institucional,
                            `${formatToSimpleDate(c.fecha_inicio)} - ${formatToSimpleDate(c.fecha_final)}`, // las dos requieren ser formateadas antes
                            ` $ ${c.precio} MXN`,
                            c.publicado ? "Sí" : "No",
                            <ActionButtonGroup>
                                <ActionButton
                                    key="btn-view"
                                    value="Ver"
                                    onClick={() => onView(c._id)}
                                />
                                <ActionButton
                                    key="btn-edit"
                                    value="Editar"
                                    onClick={() => onEdit(c._id)}
                                />
                                <ActionButton
                                    key="btn-delete"
                                    value="Borrar"
                                    onClick={() => onDelete(c._id, c.titulo)}
                                    type="danger"
                                />
                            </ActionButtonGroup>,
                        ]}
                    />
                ))}
            </Table>

        </PageWithSidebar>
    );
}
