
export const LargeButton = ({ value = 'Botón largo', classType = "info", onClick }) => {
    
    return (
        <div className="d-grid gap-2 mb-3">
            <button
                className={`btn btn-${classType} text-button`}
                type="button"
                onClick={onClick}
            >
                {value}
            </button>
        </div>
    );
}
