import React, { useState } from "react";
import _ from "lodash";
import useAxios from "../hooks/useAxios";
import { useNavigate, useParams } from "react-router";
import useAlert from "../hooks/useAlert";
import { useLoading } from "../hooks/useLoading";
import { PageWithCenteredSquare } from "../components/general/PageWithCenteredSquare";

export const RestorePasswordPage = (props) => {
  const navigate = useNavigate();
  const loading = useLoading();
  const params = useParams();
  const { put } = useAxios();
  const { openAlert } = useAlert();
  const [data, setData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
  });

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newData = { ...data, [name]: value };
    setData(newData);
    validateForm(newData);
  };

  const validateForm = (data = {}) => {
    const requiredMsg = "Este campo es requerido";
    const notMatchMsg = "Las contraseñas deben coincidir";
    const newErrors = { ...errors };
    if (!data.password) {
      newErrors.password = requiredMsg;
    } else {
      newErrors.password = "";
    }
    if (!data.confirmPassword) {
      newErrors.confirmPassword = requiredMsg;
    } else {
      newErrors.confirmPassword = "";
    }
    if (data.password !== data.confirmPassword) {
      newErrors.confirmPassword = notMatchMsg;
    }

    setErrors(newErrors);
    if (newErrors.password || newErrors.confirmPassword) return false;
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm(data)) return;

    try {
      loading.setLoading(true);
      const tmpToken = params.token;
      localStorage.setItem("token", tmpToken);
      const response = await put("/auth/new_password", {
        password: data.password,
      });
      await openAlert("Se ha cambiado la contraseña correctamente");
      navigate('/login');
    } catch (error) {
      const message = _.get(error, "response.data.errors[0].msg");
      await openAlert(
        message ||
        "Ha ocurrido un error inesperado, por favor intente más tarde"
      );
    } finally {
      localStorage.removeItem("token");
      loading.setLoading(false);
    }
  };

  return (
    <PageWithCenteredSquare>
      <form onSubmit={handleSubmit} className="needs-validation">

        <p className="background-text">Ingrese los nuevos datos</p>

        <div className="py-1">
          <label htmlFor="newcontra" className="form-label background-text">
            Nueva contraseña
          </label>
          <input
            id="newcontra"
            type="password"
            name="password"
            value={data.password}
            onChange={onChange}
            className={`${errors.password ? "is-invalid" : "is-valid"
              } form-control border`}
            placeholder="Ingrese su contraseña"
            required
          />

          <div className="invalid-feedback">{errors.password}</div>
        </div>

        <div className="py-1">
          <label
            htmlFor="repeatcontra"
            className="form-label background-text"
          >
            Repita la contraseña
          </label>
          <input
            id="repeatcontra"
            type="password"
            name="confirmPassword"
            value={data.confirmPassword}
            onChange={onChange}
            className={`${errors.confirmPassword ? "is-invalid" : "is-valid"
              } form-control border`}
            placeholder="Ingrese su contraseña"
            required
          />

          <div className="invalid-feedback">{errors.confirmPassword}</div>
        </div>

        <div className="py-4">
          <div className="d-grid gap-2">
            <button
              className="btn btn-secondary btn-blue border text-button"
              type="submit"
            >
              Cambiar contraseña
            </button>
          </div>
        </div>
      </form>
    </PageWithCenteredSquare>
  );
};
