import React from "react";

import { Link } from "react-router-dom";
import { HelpData } from '../data/help/HelpData'

import { PageWithSidebar } from "../components/general/PageWithSidebar";
import { PageTitle } from "../components/general/PageTitle";
import { PageParagraph } from "../components/general/PageParagraph";
import { CardButton } from "../components/form/CardButton";


const links = [
  { name: "Inicio", path: "/" },
  { name: "Ayuda", path: "/help" }
];

export const HelpPage = (props) => {
  return (
    <PageWithSidebar links={links}>
      <PageTitle>Ayuda</PageTitle>
      <PageParagraph>En esta sección podrá encontrar información general sobre cómo utilizar esta plataforma.</PageParagraph>

      <div className="row g-3 py-2">
        {HelpData.map((item, index) => {
          return (
            <CardButton
              key = {index}
              col = "col-md-4"
              path = {item.path}
              className = {item.className}
              classNameTitle = "fs-5"
              classNameIcon = {item.classIcon}
              icon = {item.icon}
              title = {item.title}
            />
          );
        })}
      </div>
    </PageWithSidebar>
  );
};
