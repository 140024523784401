import { createContext, useEffect, useState } from "react";
import useAxios from "../hooks/useAxios";

export const FacultiesContext = createContext({});

const initialValue = [
    { facultad: '-----', _id: '-----' },
]

export const FacultiesProvider = ({ children }) => {

    const [faculties, setFaculties] = useState(initialValue);
    const [isLoading, setIsLoading] = useState(false);
    const { get } = useAxios();

    const loadFaculties = async () => {
        try {
            setIsLoading(true);
            const resp = await get(`facultades`);
            if (resp.status === 200) {
                const result = resp.data.items;
                setFaculties(result);
                return;
            }
            console.log(resp);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        loadFaculties();
    }, []);

    return (
        <FacultiesContext.Provider value={{ faculties, isLoading, loadFaculties }}>
            {children}
        </FacultiesContext.Provider>
    );
}
