import React, { useState, useMemo, createContext } from "react";
import Alert from "./Alert";
import Notification from "./Notification";

export const AlertContext = createContext({});

class Emitter {

  constructor() {
    this.events = {};
  }

  on(type, listener) {
    this.events[type] = this.events[type] || [];
    this.events[type].push(listener);
  }

  emit(type) {
    if (this.events[type]) {
      this.events[type].forEach(function (listener, i) {
        // console.log(`Ejecución del caso ${i}`);
        listener();
      });
    }
  }

  clear(type) {
    if (!!type) {
      if (this.events[type]) {
        this.events[type] = [];
      }
    } else {
      this.events = {};
    }
  }
}

const modal = new Emitter();

export const on = (event, handler) => {
  modal.on(event, handler);
};

export const clearListeners = (event) => {
  modal.clear(event);
};

const AlertProvider = (props) => {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [oneOption, setOneOption] = useState(false);
  const [buttonColors, setButtonColors] = useState({
    accept: 'danger',
    cancel: 'secondary',
  });
  const [onCloseTimer, setOnCloseTimer] = useState(null);

  const onAccept = () => {
    modal.emit("accept");
    setShow(false);

    if (onCloseTimer) {
      clearTimeout(onCloseTimer);
      setOnCloseTimer(null);
    }
  };

  const onCancel = () => {
    modal.emit("cancel");
    setShow(false);

    if (onCloseTimer) {
      clearTimeout(onCloseTimer);
      setOnCloseTimer(null);
    }
  };

  const closeOn = (time = '3000') => {
    if (onCloseTimer) clearTimeout(onCloseTimer);

    const timer = setTimeout(() => {
      // console.log("esto debe de ejecutarse una única vez");
      onCancel()
    }, time);
    setOnCloseTimer(timer);
  }

  const value = useMemo(() => ({
    title,
    message,
    severity,
    oneOption,
    buttonColors,
    setTitle,
    setMessage,
    setSeverity,
    setOneOption,
    setButtonColors,
    setShow,
    closeOn,
  }), [
    title,
    message,
    severity,
    oneOption,
    buttonColors,
    setTitle,
    setMessage,
    setSeverity,
    setOneOption,
    setButtonColors,
    setShow,
    closeOn,
  ]);

  if (!severity || severity?.length === 0) {
    return (
      <AlertContext.Provider value={value}>
        <Alert
          visible={show}
          title={title}
          message={message}
          onAccept={onAccept}
          onCancel={onCancel}
          oneOption={oneOption}
          buttonColors={buttonColors}
        />
        {props.children}
      </AlertContext.Provider>
    )
  } else {
    return (
      <AlertContext.Provider value={value}>
        <Notification
          visible={show}
          title={title}
          severity={severity}
          message={message}
          onCancel={onCancel}
        />
        {props.children}
      </AlertContext.Provider>
    )
  }

};

export default AlertProvider;
