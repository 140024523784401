
export const BodyContainer = ({ children }) => {

    return (
        <div className="card bg-gray text-uni mb-3">
            <div className="card-body">
                { children }
            </div>
        </div>
    );
}
