import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { LoginPage } from "../pages/LoginPage";
import { LogoutPage } from "../pages/LogoutPage";
import { RestorePasswordPage } from "../pages/RestorePasswordPage";
import { ForgotPasswordPage } from "../pages/ForgotPasswordPage";
// import { EmbedPage } from "../pages/EmbedPage";
// import { EmbedHome } from "../pages/EmbedHome";


const PublicRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/embed/courses" exact element={<EmbedPage />} />
      <Route path="/embed/home" exact element={<EmbedHome />} /> */}

      <Route path="/restore-password/:token" exact element={<RestorePasswordPage />} />
      <Route path="/forgot-password" exact element={<ForgotPasswordPage />} />
      <Route path="/login" exact element={<LoginPage />} />
      <Route path="/logout" exact element={<LogoutPage />} />
      {/* No habrá home... verdad?! */}
      {/* <Route path="/" exact element={<Home/>} /> */}
      <Route path="/*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default PublicRoutes;
