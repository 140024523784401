import React, { useState } from "react";
import useAxios from "../hooks/useAxios";
import { useNavigate } from "react-router";
import useAlert from "../hooks/useAlert";

import { useLoading } from "../hooks/useLoading";
import { FormTextInput } from "../components/form/FormTextInput"
import { PageWithCenteredSquare } from "../components/general/PageWithCenteredSquare";

export const ForgotPasswordPage = (props) => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { put } = useAxios();
  const { openAlert } = useAlert();
  const loading = useLoading();

  const onChange = (event) => {
    const name = event.target.name;
    if (name === "email") {
      setEmail(event.target.value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (email) {
      try {
        loading.setLoading(true);
        const response = await put("/auth/forgot_password", { correo: email });
        if (response.data && response.data.msg) {
          await openAlert(
            response.data.msg ||
            "Se ha enviado un link para reestablecer contraseña al correo ingresado"
          );
          navigate("/login", { replace: true });
        }
      } catch (error) {
        await openAlert("Ha ocurrido inesperado, por favor intente más tarde");
        console.log({ error });
      } finally {
        loading.setLoading(false);
      }
    }
  };

  const inputFeedBack = () => {
    "use strict";

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.querySelectorAll(".needs-validation");

    // Loop over them and prevent submission
    Array.from(forms).forEach((form) => {
      form.addEventListener(
        "submit",
        (event) => {
          if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
          }

          form.classList.add("was-validated");
        },
        false
      );
    });
  };

  return (
    <PageWithCenteredSquare>
      <form
        onSubmit={handleSubmit}
        className="needs-validation"
        noValidate
      >

        <div className="py-2">
          <p className="text-uni">
            Para poder iniciar el cambio de contraseña, ingrese su dirección de
            correo electronico.
          </p>
          <p className="text-uni">
            Haga click en enviar correo, y despues siga los pasos que se
            encuentran en este.
          </p>

          <FormTextInput
            label="Correo electrónico"
            name="email"
            value={email}
            type="text"
            required={true}
            onChange={onChange}
            placeholder='Ingrese su correo electronico'
            invalidFeedback="Ingrese una dirección de correo electrónico"
          />

          <div className="invalid-feedback">LLena el campo</div>
        </div>

        <div className="py-4">
          <div className="d-grid gap-2">
            <button
              className="btn btn-secondary btn-blue border text-button"
              type="submit"
              onClick={inputFeedBack}
            >
              Enviar correo
            </button>
          </div>
        </div>
      </form>
    </PageWithCenteredSquare>
  );
};
