import { faFileCode } from "@fortawesome/free-solid-svg-icons";

export const ExportsData = [
    {
        id: 'areas-academicas',
        pathname: 'home',
        name: 'Áreas académicas',
        type: 'Componentes',
        icon: faFileCode,
        format: 'HTML, CSS, JS',
        desc: 'Código HTML correspondiente al componente de las áreas académicas y sus instituciones.'
    },
    {
        id: 'lista-cursos',
        pathname: 'page',
        name: 'Cursos por facultad',
        type: 'Componentes',
        icon: faFileCode,
        format: 'HTML, CSS, JS',
        desc: 'Código HTML correspondiente al componente de la lista de cursos por facultad.'
    },
]