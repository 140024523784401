
export const TableRow = ({ values = ['and', 'these', 'values'] }) => {

    return (
        <tr>
            {values.map(value =>
                <td key={`${value}-key`}>{value}</td>
            )}
        </tr>
    );
}
