
export const ActionButton = ({ value = 'Botón', onClick = () => { }, type = 'info' }) => {

    return (
        <div className="col-xxl-4">
            <div className="d-grid gap-2">
                <button
                    type="button"
                    className={`btn btn-${type} text-button text-action-button`}
                    onClick={onClick}
                >
                    {value}
                </button>
            </div>
        </div>
    );
}
