import { faCode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { BodyContainer } from "../components/general/BodyContainer";
import { PageParagraph } from "../components/general/PageParagraph";
import { PageTitle } from "../components/general/PageTitle";
import { PageWithSidebar } from "../components/general/PageWithSidebar"
import { ExportsData } from "../data/exports/ExportsData";

const links = [
    { name: "Inicio", path: "/" },
    { name: "Exportaciones", path: "/exports" },
];

export const ExportsPage = () => {

    return (
        <PageWithSidebar links={links}>

            <PageTitle>Exportaciones</PageTitle>
            <PageParagraph>
                En esta página se puede encontrar el código fuente para que
                sea insertado en la página principal de <b>Educación Continua</b>.
            </PageParagraph>

            {
                ExportsData.map(({id, name, icon}) => (
                    <BodyContainer key={`${id}-key`}>
                        <Link to={`/exports/${id}`} className="text-uni">
                            <FontAwesomeIcon
                                icon={icon}
                                className="pe-2 fa-lg"
                            />
                            {name}
                        </Link>
                    </BodyContainer>
                ))
            }

        </PageWithSidebar>
    )
}
