import { format } from "date-fns";
import { roles } from "../data/role";

const dateOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
};

export function formatRole(rol = "") {
  if (roles[rol]) {
    return roles[rol];
  }
  return "Unknown role";
}

export function formatToSimpleDate(stringDate = "") {
  try {
    return new Date(stringDate).toLocaleDateString('es-MX', dateOptions);
  } catch (error) {
    console.log('Formatting stringDate failed. Default case returned.');
    return new Date().toLocaleDateString('es-MX', dateOptions);
  }
}

export function formatToISODate(stringDate = "") {
  try {
    return new Date(stringDate).toISOString();
  } catch (error) {
    console.log('Formatting stringDate failed. Default case returned.');
    return new Date().toISOString();
  }
}

// srly
export function formatToUploadableDate(date) {
  if (date) {
    return format(date, "yyyy-MM-dd");
  }
  return date;
}

export function getFaculty(prop, value, array) {
  return array.find(e => e[prop] === value);
}

// ultra copy-pasteado
export async function URL2File (imageURL, name) {
  const response = await fetch(imageURL);
  const blob = await response.blob();
  const file = new File([blob], name, { type: blob.type });
  // console.log(file);
  return file;
}

export function obtainRole (role) {
  if (role === 'ADMIN_ROLE') {
    return 'Administrador'
  } else if (role === 'SUPER_ADMIN') {
    return 'Super Administrador'
  } else {
    return 'Usuario'
  }
}