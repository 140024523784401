import * as yup from 'yup';

const MAX_FILE_SIZE = 2000000;

export const userSchema = yup.object({
    name: yup.string()
        .required('El nombre es obligatorio'),
    //ROL
    role: yup.string()
        .notOneOf(['none'], 'Seleccione una opción válida')
        .required('El rol es obligatorio'),
    faculty: yup.string()
        .notOneOf(['none'], 'Seleccione una opción válida')
        .required('La facultad es obligatoria'),
    //PASSWORD
    password: yup.string()
        .required('La contraseña es obligatoria'),
    email: yup.string()
        .email('El correo electrónico no es válido')
        .required('El correo electrónico es obligatorio'),
    image: yup.mixed()
        .test("fileSize", "El archivo es muy grande (mayor que 2 MB)", (value) => {
            if (value) return value.size <= MAX_FILE_SIZE;
            return true;
        }),
});