import React from "react";
import { Box, Alert, AlertTitle, Collapse, IconButton } from "@mui/material";

const Notification = (props) => {
  return (
    <Box className="notification-bar">
      <Collapse in={props.visible}>
        <Alert
          severity={props.severity}
          onClose={props.onCancel}
        >
          <AlertTitle>{props.title}</AlertTitle>
          {props.message}
        </Alert>
      </Collapse>
    </Box>
  );
};

export default Notification;
