import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { FormDateInput } from "../form/FormDateInput";

export const SearchBar = ({
  searchValue = "",
  displayDatePicker = false,
  dateLabel = "",
  dateValue = "",
  onChangeDate = () => {},
  onChangeSearchValue = () => {},
  onSubmitSearchValue = () => {},
  total = 10,
}) => {
  return (
    <div className="navbar bg-buap searchbar admin-searchbar">
      <div className="container-fluid">
        <span className="navbar-brand text-white mx-2">
          Mostrando {total} elementos
        </span>
        <form className="d-flex" role="search" onSubmit={onSubmitSearchValue}>
          <button className="btn me-2 decoration-none" type="submit">
            <FontAwesomeIcon icon={faSearch} className={"fa-1x text-white"} />
          </button>
          <input
            name="search"
            value={searchValue}
            onChange={onChangeSearchValue}
            className="form-control me-2"
            type="search"
            placeholder="Escriba una búsqueda"
            aria-label="busqueda"
          />
          {displayDatePicker && (
            <DatePicker
              id="date"
              name="date"
              selected={dateValue}
              dateFormat="dd/MM/yyyy"
              onChange={onChangeDate}
              placeholderText={dateLabel}
              className="form-control"
              popperClassName="shadow rounded"
              todayButton="Hoy"
              locale="es"
              closeOnScroll
            />
          )}
        </form>
      </div>
    </div>
  );
};
