import React, { useState, createContext, useEffect, useMemo } from "react";
import useAxios from "../hooks/useAxios";

const defaultUser = {
  estado: false,
  nombre: "",
  correo: "",
  rol: "",
  facultad: "",
  img: "",
  id: "",
};

export const UserContext = createContext(defaultUser);

const UserProvider = (props) => {
  const { get } = useAxios();
  const [user, setUser] = useState(defaultUser);

  useEffect(() => {
     getMyInfo();
   }, []);

  const getMyInfo = async () => {
    try {
      const response = await get("/auth/my_info");
      if (response.data && response.data.id) {
        setUser({ ...user, ...response.data });
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const value = useMemo(() => ( user ), [user]);
  // console.log(value);

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  );
};

export default UserProvider;
