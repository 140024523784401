import { useEffect, useState } from "react"

export const FormCheckInput = ({
    label,
    name,
    value = [],
    options = [{
        id: 'id',
        value: 'value',
        option: 'option',
    }],
    onChange,
    invalidFeedback,
}) => {

    const display = invalidFeedback?.length > 0 ? 'd-block' : 'd-none';

    const onClick = ({ target }) => {
        const { value: targetValue, checked } = target;

        let newValue;
        if (checked) {
            newValue = [...value, targetValue];
        } else {
            newValue = value.filter(v => v !== targetValue);
        }

        onChange({ target: { name: name, value: newValue } });
    }

    return (
        <div className="py-2">
            {label && <label htmlFor={name} className="form-label">{label}</label>}
            {options.map(({ option, value: optionValue }) => (
                <div className="form-check" key={option + optionValue}>
                    <input
                        id={option + optionValue}
                        type='checkbox'
                        name={name}
                        value={optionValue}
                        onChange={onClick}
                        checked={value.includes(optionValue)}
                        className="form-check-input border-check"
                    />
                    <label htmlFor={option + optionValue} className="form-label">{option}</label>
                </div>
            ))}
            <div className={`invalid-feedback ${display}`}>
                {invalidFeedback}
            </div>
        </div>
    )
}
