
import { BrowserRouter } from "react-router-dom";
import AlertProvider from "./components/AlertProvider";
import { AppRouter } from "./routes/AppRouter";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import { LoadingProvider } from "./providers/LoadingProvider";

function App() {

  return (
    <AlertProvider>
      <LoadingProvider>
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </LoadingProvider>
    </AlertProvider>
  );
}

export default App;
