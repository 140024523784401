import { useEffect, useMemo, useState } from "react"
import { ActionFormButton } from "../components/form/ActionFormButton"
import { ActionFormButtonGroup } from "../components/form/ActionFormButtonGroup"
import { FormImageSelectInput } from "../components/form/FormImageSelectInput"
import { FormSelectInput } from "../components/form/FormSelectInput"
import { FormTextInput } from "../components/form/FormTextInput"
import { BodyContainer } from "../components/general/BodyContainer"
import { LargeButton } from "../components/general/LargeButton"
import { PageWithSidebar } from "../components/general/PageWithSidebar"
import { PageParagraph } from "../components/general/PageParagraph"
import { PageTitle } from "../components/general/PageTitle"
import { useForm } from "../hooks/useForm"


import { useNavigate, useParams } from "react-router-dom"
import { useFacultyInfo } from "../hooks/useFacultyInfo"
import { useLoading } from "../hooks/useLoading"
import useAxios from "../hooks/useAxios"
import useAlert from "../hooks/useAlert";
import { obtainRole } from "../functions";

import { userSchema } from "../schemas/userSchema";

const initialValues = {
    name: '',
    email: '',
    role: 'USER_ROLE',
    faculty: '',
    password: '',
    image: null,
};

export const AddUserPage = () => {

    const { facID, userID } = useParams();
    const { faculty: facObject } = useFacultyInfo({ id: facID });
    const { setLoading } = useLoading();
    const { get, put, post, del } = useAxios();
    const navigate = useNavigate();

    const { openAlert } = useAlert();

    const isEdit = !!userID;
    const haveFaculty = !!facID;

    const facPart = haveFaculty ? "/" + facID : '';

    const links = useMemo(() => {
        return [
            { name: "Inicio", path: "/" },
            { name: "Usuarios", path: "/users" },
            ...(haveFaculty ? [{ name: facObject.name, path: `/users/${facID}` }] : []),
            ...(isEdit ? [
                { name: "Editar usuario", path: `/edit/user/${userID}` },
            ] : []),
            ...(!isEdit ? [
                { name: "Agregar usuario", path: `/edit/user/${userID}` },
            ] : []),
        ];
    }, [facID, facObject]);

    const {
        name,
        email,
        role,
        faculty,
        password,
        image,
        onInputChange,
        setFormState,
        formState,
    } = useForm(haveFaculty
        ? { ...initialValues, faculty: facID }
        : initialValues
    );



    
    const [imageURL, setImageURL] = useState('');
    const [imageInfo, setImageInfo] = useState({
        hadImageBefore: false,
        deletedImage: false,
    });
    const [faculties, setFaculties] = useState([{
        value: 'none',
        option: 'Seleccione una opción'
    }]);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        loadFaculties();
        if (isEdit) loadUser();
    }, []);

    const loadUser = async () => {
        try {
            setLoading(true);
            const resp = await get(`/usuarios?id=${userID}`);
            if (resp.status === 200) {
                const user = resp.data.items;
                //// console.log(course);
                setFormState(prev => ({
                    ...prev,
                    name: user.nombre,
                    email: user.correo,
                    role: user.rol,
                    faculty: user.facultad,
                    password: user.password,
                }));
                setImageURL(user.img || '');
                setImageInfo({
                    ...imageInfo,
                    hadImageBefore: !!user.img,
                })
                return;
            }
            console.log(resp);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }


    
    const loadFaculties = async () => {
        try {
            setLoading(true);
            const resp = await get(`facultades`);
            if (resp.status === 200) {
                const facs = resp.data.items;
                const mapped = facs.map(i => ({
                    value: i._id,
                    option: i.facultad,
                }));
                setFaculties(prev => [...prev, ...mapped]);
                return;
            }
            console.log(resp);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }


    const onChangeImage = (event) => {
        const img = event.target.files[0];
        if (!!img) {
            onInputChange({ target: { name: 'image', value: img } });

            const url = !!img ? URL.createObjectURL(img) : '';
            setImageURL(url);
        }
    }

    const onRemoveImage = () => {
        onInputChange({ target: { name: 'image', value: null } });
        setImageURL('');
        setImageInfo({
            ...imageInfo,
            deletedImage: true,
        });
    }

    const putErrors = (errorList = []) => {
        const errorState = {};

        for (let v of errorList) {
            errorState[v.path] = v.message;
        }

        setErrors(errorState);
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        console.log({ ...formState, image });

        try {
            const valid = await userSchema.validate(formState, { abortEarly: false });
            putErrors([]);
        } catch (error) {
            putErrors(error.inner);
            return;
        }

        try {
            setLoading(true);

            const formData = new FormData();

            formData.append('nombre', name);
            formData.append('correo', email);
            formData.append('rol', role);
            formData.append('facultad', faculty);
            //formData.append('password', password);
            if (image) formData.append('archivo', image);

            let resp;
            if (!isEdit) {
                resp = await post(`usuarios`, formData);
            } else {
                resp = await put(`usuarios/${userID}`, formData);
            }
            if (resp.status === 200) {
                if (imageInfo.deletedImage && imageInfo.hadImageBefore) {
                    resp = await del(`uploads/files/usuarios/${userID}`);
                }
                // TODO: mostrar modal de éxito

                openAlert(
                    <span>El {obtainRole(role)} ha sido agregado</span>,
                    <span>El {obtainRole(role)}
                    <span className="fw-bold"> {name} </span>
                    ha sido agregado correctamente
                    </span>,
                    "success"
                )

                navigate(-1, { replace: true });
            }
            console.log(resp);
        } catch (error) {
            console.log(error);

            openAlert(
                <span>Error en la eliminación</span>,
                <span>No se ha podido completar la eliminación
                    debido a un error inesperado, intentelo más tarde
                </span>,
                "error"
            )
        } finally {
            setLoading(false);
        }
    }




    
    
    const onModal = async (nombre, rol) => {
        const respModal = await openAlert(
            "¿Cancelar acción?",
            <div>
                <p>Al presionar
                "<span className="fw-bold">Aceptar</span>", el {obtainRole(rol)}
                <span className="fw-bold"> {nombre} </span>no será {isEdit ? 'Editado' : 'Añadido'}
                </p>
            </div>,
        )

        if (respModal) {
            console.log();
        } else {
            navigate(-1)
            openAlert(
                <span>Acción cancelada</span>,
                <span>El {obtainRole(rol)} <span className="fw-bold"> {nombre} </span>
                    no se {isEdit ? 'Editó' : 'Añadió'}
                </span>,
                "warning"
            )
        }
    }




    return (
        <PageWithSidebar links={links}>

            <form className="needs-validation" noValidate onSubmit={onSubmit}>

                <PageTitle>{isEdit ? "Editar" : "Agregar"} usuario</PageTitle>
                <PageParagraph>
                    Llene el siguiente formulario.
                </PageParagraph>

                <BodyContainer>

                    <div className="row g-3">

                        <div className="col-md-4">
                            <FormImageSelectInput
                                label="Imagen de perfil"
                                name="profile-pic"
                                value={image}
                                src={imageURL}
                                altText="Previsualización de imágen de peril"
                                onChange={onChangeImage}
                                onClickRemove={onRemoveImage}
                                invalidFeedback="Rellene el campo solicitado"
                            />
                        </div>

                        <div className="col-md-8">
                            <FormTextInput
                                label="Nombre"
                                name="name"
                                value={name}
                                type="text"
                                required
                                onChange={onInputChange}
                                placeholder='Nombre de la persona'
                                invalidFeedback="Rellene el campo solicitado"
                            />

                            <FormTextInput
                                label="Correo electrónico"
                                name="email"
                                value={email}
                                type="email"
                                required
                                onChange={onInputChange}
                                placeholder='Correo electrónico de la persona'
                                invalidFeedback="Rellene el campo solicitado"
                            />

                            <FormSelectInput
                                label="Rol"
                                name="role"
                                value={role}
                                options={[
                                    { value: 'ADMIN_ROLE', option: 'Administrador', },
                                    { value: 'USER_ROLE', option: 'Usuario', },
                                ]}
                                onChange={onInputChange}
                                invalidFeedback="Seleccione una de las opciones"
                            />

                            <FormSelectInput
                                label="Facultad"
                                name="faculty"
                                value={faculty}
                                options={faculties}
                                onChange={onInputChange}
                                invalidFeedback="Seleccione una de las opciones"
                            />
                        </div>

                    </div>

                </BodyContainer>

                {/* Este apartado SOLAMENTE saldrá cuando se entre como modo edición */}
                {
                    isEdit ? 
                        <div>
                            <PageParagraph>
                                En caso de querer cambiar la contraseña, haga click en el siguiente botón.
                            </PageParagraph>

                            <BodyContainer>
                                <LargeButton value="Reestablecer contraseña" />
                            </BodyContainer>
                        </div> 
                    :  
                        <div>
                        </div>
                }
                {/* Aqui termina esa wea */}



                <PageParagraph>
                                Para confirmar la operación, ingrese su contraseña actual en el siguiente campo.
                            </PageParagraph>

                            <BodyContainer>
                                <FormTextInput
                                    label="Contraseña"
                                    name="password"
                                    type="password"
                                    required
                                    onChange={onInputChange}
                                    placeholder='*************'
                                    invalidFeedback="Ingrese su contraseña"
                                />
                            </BodyContainer>

                <ActionFormButtonGroup>
                    <ActionFormButton 
                        classType='secondary' 
                        type="button" 
                        value="Cancelar" 
                        onClick={() => onModal(name, role)} 
                    />
                    <ActionFormButton classType='info' type="submit" value="Aceptar" />
                </ActionFormButtonGroup>

            </form>

        </PageWithSidebar>
    )
}