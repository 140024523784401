import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Link, NavLink } from "react-router-dom";
import useMyInfo from "../hooks/useMyInfo";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNavicon, faHome, faUser, faMortarBoard, faFileCode, faQuestionCircle, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

import logo from "../assets/img/logo-buap-white.png"
import {SidebarData} from './SidebarData'
import { useTokens } from "../hooks/useTokens";


export const AdminSidebar = (props) => {

  const { freeTokens } = useTokens();
  const navigate = useNavigate();
  const { role } = useMyInfo();

  const logout = () => {
    freeTokens();
    // navigate("/login", { replace: true });
    window.location.reload(false);
  };

  const[show, setShow] = useState(true);


  return (


    
    <main className={show ? 'space-toggle' : null}>
        <header className={`header ${show ? 'space-toggle' : null}`}>
            <div 
                className="header-toggle"
                onClick={()=>setShow(!show)}>
                <FontAwesomeIcon 
                    icon={ faNavicon } 
                    className={"pe-2 fa-xl"}
                />
            </div>
        </header>

        <aside className={`sidebar ${show ? 'show' : null}`}>
            <nav className="nav">
                <div>
                    <Link to="/" className="nav-logo">

                        <div className="row">
                            <div className="col-12">
                                <img src={logo} 
                                className={show ? 'img-max' : 'img-min'} 
                                alt="imagen logo"/>
                            </div>
                            <div className="col-12">
                                <span className={`nav-logo-name ${show ? 'show-title' : 'hide-title' }`}>
                                    Educación Continua
                                </span>
                            </div>
                        </div>
                    </Link>

                    <div className="nav-list">


                        {SidebarData.map((item, index) => {
                            return (
                                <NavLink key={index} to={item.path} 
                                        className={({isActive}) => (`nav-link ${isActive ? 'active' : '' }`)}>
                                        {item.icon}
                                        <span className={`nav-link-name ${show ? 'show-title' : 'hide-title' }`}>
                                            {item.title}
                                        </span>
                                </NavLink>
                            );
                        })}


                        {/*
                        
                        <Link to="/" className="nav-link">
                            <FontAwesomeIcon 
                                icon={ faHome } 
                                className={"fa-xl nav-link-icon"}
                            />
                            <span className={`nav-link-name ${show ? 'show-title' : 'hide-title' }`}>
                                Inicio
                            </span>
                        </Link>

                        
                        <Link to="/" className="nav-link">
                            <FontAwesomeIcon 
                                icon={ faUser } 
                                className={"fa-xl nav-link-icon"}
                            />
                            <span className={`nav-link-name ${show ? 'show-title' : 'hide-title' }`}>
                                Usuarios
                            </span>
                        </Link>
                        <Link to="/" className="nav-link">
                            <FontAwesomeIcon 
                                icon={ faMortarBoard } 
                                className={"fa-xl nav-link-icon"}
                            />
                            <span className={`nav-link-name ${show ? 'show-title' : 'hide-title' }`}>
                                Cursos
                            </span>
                        </Link>
                        <Link to="/" className="nav-link">
                            <FontAwesomeIcon 
                                icon={ faFileCode } 
                                className={"fa-xl nav-link-icon"}
                            />
                            <span className={`nav-link-name ${show ? 'show-title' : 'hide-title' }`}>
                                Exportaciones
                            </span>
                        </Link>
                        <Link to="/" className="nav-link">
                            <FontAwesomeIcon 
                                icon={ faQuestionCircle } 
                                className={"fa-xl nav-link-icon"}
                            />
                            <span className={`nav-link-name ${show ? 'show-title' : 'hide-title' }`}>
                                Ayuda
                            </span>
                        </Link>
                        */}
                    </div>

                </div>


                <button onClick={logout} className="nav-footer">
                    <FontAwesomeIcon 
                        icon={ faSignOutAlt } 
                        className={"pe-2 fa-xl nav-link-icon"}
                    />
                    <span className={`nav-link-name ${show ? 'show-title' : 'hide-title' }`}>
                        Cerrar sesión
                    </span>
                </button>

            </nav>
        </aside>

        

        {props.children}

    </main>

  );
};
